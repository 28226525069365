import React from "react";
import AdminNav from "./AdminNav";
import DashboardHeader from "./DashboardHeader";
// import DashboardSearch from './DashboardSearch';
import DashboardTable from './DashboardTable';


function SindhApplicantReport() {
    return (
        <>
            <div className="app-admin-wrap layout-sidebar-large">
                <DashboardHeader />
                <AdminNav />
                <div className="main-content-wrap d-flex flex-column sidenav-open">
                    <h1>Sindh Applicant Report</h1>

                    {/* <DashboardSearch onSearch={handleSearch} /> */}


                    <DashboardTable title={'Sindh Applicant Report'} event={'sindh'} type={"report"}  province={'Sindh'}/>

                </div>
            </div>
        </>
    );
}

export default SindhApplicantReport;