import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationContainer, NotificationManager, } from "react-notifications";
import DashboardHeader from "./DashboardHeader";


const GreenEnergyCommitment = () => {
  const [/*commitmentData*/, setCommitmentData] = useState({});
  
  const [skills, setSkills] = useState("");
  const [content, setContent] = useState("");
  const [projectDetail, setProjectDetail] = useState("");
  const [wordCountError, setWordCountError] = useState("");

  const [referalData, setReferalData] = useState(null);
  const [referalId, setReferalId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [personalStatement, setPersonalStatement] = useState(false);
  const [personalStatementId, setPersonalStatementId] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("auth_user_info"));
  const applicantID = auth ? auth.id : null;
  const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
 console.log("token",token)
 const applicationId = parseInt(localStorage.getItem('id'), 10); // Convert to integer
 const status = localStorage.getItem('application_status'); // Assuming the token is stored in localStorage


 useEffect(() => {
  const disabledStatuses = ['approved', 'new', 'final_stage', 'rejected'];
  if (disabledStatuses.includes(status)) {
    setIsDisabled(true);
  } else {
    setIsDisabled(false);
  }
}, [status]); // Re-evaluate when the status changes


  useEffect(() => {

    // All Info

      // Fetch the latest campaign first
  fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}` // JWT token of the logged-in user
    }
  })
  .then(response => response.json())
  .then(campaignResponse => {
    const latestCampaign = campaignResponse.data[0]; // Get the latest campaign
    setLoading(false)

    if (!latestCampaign) {
      console.error('No campaign found');
      return; // If no campaign exists, handle appropriately
    }

    console.log('Latest campaign:', latestCampaign);

    // Fetch personal statements related to both the user and the latest campaign
    fetch(`${process.env.REACT_APP_BASE_URL_strapi}/personal-statements?filters[campaign][id][$eq]=${latestCampaign.id}&filters[application][id][$eq]=${applicationId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}` // JWT token of the logged-in user
      }
    })
      .then(response => response.json())
      .then(personal_statements => {
        console.log('User with personal statements:', personal_statements);

        const personalStatements = personal_statements.data; // Extract personal statements

        if (personalStatements.length > 0) {
          console.log('Personal Statement related to the user:', personalStatements[0]);

          setContent(personalStatements[0].attributes.personal_statement || "");
          setSkills(personalStatements[0].attributes.skills || "");
          setReferalId(personalStatements[0].attributes.social_media || 0);
          setProjectDetail(personalStatements[0].attributes.project_detail || 0)
          setPersonalStatement(true);
          setPersonalStatementId(personalStatements[0].id);
        } else {
          console.log('No personal statements related to the latest campaign found for the user');
        }
      })
      .catch(error => {
        console.error('Error fetching user personal statements:', error);
      });
  })
  .catch(error => {
    console.error('Error fetching the latest campaign:', error);
  });

  }, []);


  // word coount
  const handleInputChange = (e) => {
    const wordCount = countWords(e.target.value);
    if (wordCount <= 800) {
      setContent(e.target.value);
      setError(null); // Clear the error
    } else {
      setError('Word count exceeds 800');
    }
  };
  const handleProjectDetailChange = (e) => {
    const inputText = e.target.value;
    const wordCount = inputText.trim().split(/\s+/).length;
  
    // Check if word count exceeds 500
    if (wordCount > 800) {
      setWordCountError("Please limit your description to 800 words.");
    } else {
      setWordCountError(""); // Clear error if under 500 words
      setProjectDetail(inputText); // Update the textarea value
    }
  };
  const handlePaste = (e) => {
    // Get pasted data
    const pastedData = e.clipboardData.getData('text');

    // Calculate word count of current content and pasted data
    const wordCount = countWords(content + pastedData);

    // Check if word count exceeds limit
    if (wordCount > 800) {
      // console.error('Error: Word count exceeds 800');
      setError('Word count exceeds 800'); // Set the error
      e.preventDefault(); // Prevent the paste operation
    }
  };

  const countWords = (text) => {
    return text.trim().split(/\s+/).length;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
 // Clear any previous error messages
 setErrorMessage('');

 // Validate required fields
 if (!content || !referalId || !skills) {
   setErrorMessage('Please fill out all required fields.');
   return;
 }

    const postData = {
      personal_statement: content,
      social_media: referalId ,
      skills: skills,
      project_detail:projectDetail
    };
     console.log(postData);
     if(personalStatement){
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/personal-statements/${personalStatementId}`, {
          method: 'put', 
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Ensure content-type is set to JSON
          },
          body: JSON.stringify({ data: postData }) // Send the data wrapped in a 'data' object
        });
    
        if (!response.ok) {
          throw new Error('Failed to submit form');
        }
    
        const data = await response.json();
        console.log(data);
        navigate("/application/reference-letter");
      } catch (error) {
        console.error('Error:', error);
      }
    }else{
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/personal-statements`, {
        method: 'POST', 
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json', // Ensure content-type is set to JSON
        },
        body: JSON.stringify({ data: {...postData ,applicationId},}) // Send the data wrapped in a 'data' object
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit form');
      }
  
      const data = await response.json();
      console.log(data);
      localStorage.setItem("personal_statement", data.id);

      navigate("/application/reference-letter");
    } catch (error) {
      console.error('Error:', error);
    }
  }
  };

  return (
    <div>
      {/* Header Start */}
      <div className="mb-5" >

        <DashboardHeader />
      </div>

      <div className="container row justify-content-center m-auto mb-5">
        <div className="col-md-10">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Navbar />
            </div>
            <div className="col-sm-12 col-md-8 mb-4">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>

              ) : (
                <form onSubmit={handleSubmit}>
                        {errorMessage && <p className="text-danger">{errorMessage}</p>}

                  <div className="card ">
                    <div className="card-body">
                      <h4 >
                        Personal Satement
                      </h4>
                      <p style={{ width: "100%" }}>
                      Through your personal statement, help us understand:
                      </p>
                      <ol >
                        <li >
                        What ignites your passion in your chosen field? Share your most significant achievements and explain how your work creates a meaningful impact.
                        </li>
                        <li >
                        What draws you to this opportunity? How will this fellowship enhance your journey, and what distinct qualities would you bring to our cohort?
                        </li>
                        <li>
                        How do you plan to influence positive transformation - from your immediate workplace to broader societal impact?
                        </li>
                        <li >
                        What challenges inspire you to act? How do you hope to contribute to making the world better?
                        </li>
                        <li >
                        Where do you envision yourself in five years? How does this fellowship fit into your long-term aspirations?
                        </li>
                      </ol>
                      <p>(Word count: 800)</p>
                      <div className="form-group mb-4">
                        <label htmlFor="coverLetter">Personal Statement  <span className="text-danger">*</span></label>
                        <textarea
                          rows="20"
                          id="coverLetter"
                          placeholder="Type Statement"
                          className="form-control w-100"
                          value={content || ""}
                          onPaste={handlePaste}
                          onChange={handleInputChange}
                          disabled={isDisabled}

                        ></textarea>
                        <div>
                          Word Count: {countWords(content)}
                          {error && <div className="text-danger">{error}</div>}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="skills">List any relevant technical skills or knowledge related to
                          renewable energy (e.g., coursework, projects, certifications).<span className="text-danger">*</span></label>
                        <textarea
                          rows="10"
                          id="skills"
                          placeholder="List any relevant technical skills……"
                          className="form-control w-100"

                          value={skills || ''}
                          onChange={(e) => setSkills(e.target.value)}
                          disabled={isDisabled}

                        ></textarea>
                      </div>
                      <div className="form-group">
                      <label htmlFor="projectDetail">
    <p>As part of the fellowship, participants are required to propose a project idea related to energy and climate that can drive meaningful impact within communities. This idea should have the potential to grow into a sustainable organization, community-based initiative, or startup that advances climate action, energy solutions, or grassroots empowerment. During the two-week program, participants will refine and develop their projects with the guidance of expert mentors.</p>

    <p>After completing the program, participants will continue working on these projects over the following three months, culminating in a final deliverable (such as a report, business plan, or 2-3 minute video) showcasing their work and vision.</p>

    <p>Through a competitive process, promising projects will be selected to receive additional support. Alumni with impactful proposals can qualify for financial aid, including small grants for up to three months or extended incubation support for up to one year.
</p>

    <p> <b>Please describe the project you would like to work on.</b> Explain the impact you hope to create, why this project is important to you, what deliverables you plan to produce, and the approach you’ll take to achieve your vision. (800 word limit)<span className="text-danger">*</span></p>
  </label>
                        <textarea
                            rows="10"
                            id="projectDetail"
                            placeholder="Type..."
                            className="form-control w-100"
                            value={projectDetail}
                            onChange={handleProjectDetailChange}
                            disabled={isDisabled}
                          ></textarea>
                          {wordCountError && <div className="text-danger">{wordCountError}</div>}
                      </div>


                      <div className="form-group mb-3 col-sm-12 col-md-12">
                        <label htmlFor={`socialMedia`}>How did you hear about this fellowship opportunity? <span className="text-danger">*</span></label>
                        <select
                          id={`socialMedia`}
                          className="form-control"
                          name="socialMedia"
                          value={referalId || ''}
                          onChange={(e) => setReferalId(e.target.value)}
                          disabled={isDisabled}

                        >
                          {/* {referalId === '' ? <option key={0} value={0}>Select option</option> : null} */}
                          <option key={0} value={0}>Select option</option>

                          <option value= "Facebook">Facebook</option>
                          <option value="From a Friend">From a Friend</option>
                          <option value="LinkedIn">LinkedIn</option>
                          <option value="Twitter">Twitter</option>
                          <option value="Website">Website</option>
                          <option value="Youtube">Youtube</option>

                        </select>
                      </div>


                    </div>
                    <div className="card-footer">
                      <div className="mc-footer">
                        <div className="row text-center">
                          <div className="col-lg-12 ">
                            <button type="submit" className="btn btn-primary text-white m-1"                           disabled={isDisabled}
>
                              Save & Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default GreenEnergyCommitment;
