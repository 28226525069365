import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { Parser } from 'json2csv';
import FileSaver from 'file-saver';
import axios from 'axios';

const DashboardTable = ({ event, title, type,status ,gender,province}) => {
    console.log("event",event)
    console.log("title",title)
    console.log("type",type)
    console.log("status",status)
    console.log("gender",gender)
    console.log("province",province)

    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    console.log("token",token)
    const authUserInfo = JSON.parse(localStorage.getItem('auth_user_info')); // Get the stored JSON string and parse it to an object
    const userId = authUserInfo.id; // Access the 'id' property
    console.log("User ID:", userId);
    const navigate = useNavigate();
    const [applications, setApplications] = useState([]);
    const [realapplications, setRealApplications] = useState([]);

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    let apiCallURL = null;
    let api = null;

   
    if (status) {
      api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[status][$eq]=${status}&populate=personal_statement,evaluations.users_permissions_user` ;
  } else if (gender) {
      api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[gender][$eq]=${gender}&populate[evaluations][populate]=users_permissions_user`;
  } else if (province) {
      api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[province][$eq]=${province}&populate[evaluations][populate]=users_permissions_user`;
  }


  // Fetch Applications and Evaluations information
  useEffect(() => {
    const fetchApplicationsWithEvaluations = async () => {
        try {
            const response = await fetch(api, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            const applicationsData = await response.json();
            const applications = applicationsData.data;

            // Process evaluations and calculate the sum of the relevant fields
            const updatedApplications = applications.map(application => {
                const evaluations = application.attributes.evaluations?.data || [];

                const updatedEvaluations = evaluations.map(evaluation => {
                    const attributes = evaluation.attributes;
                    // Calculate the sum of desired fields
                    const sum = (attributes.creativity || 0) +
                                (attributes.expression || 0) +
                                (attributes.future_ambition || 0) +
                                (attributes.past_ambition || 0) +
                                (attributes.relevance || 0);

                    // Return evaluation with the sum
                    return {
                        ...evaluation,
                        sum: sum, // Add the sum field to the evaluation
                    };
                });

                // Update the application with the modified evaluations
                return {
                    ...application,
                    attributes: {
                        ...application.attributes,
                        evaluations: {
                            ...application.attributes.evaluations,
                            data: updatedEvaluations, // Set updated evaluations with the sum
                        },
                    },
                };
            });
         console.log("updatedApplications",updatedApplications)
         console.log("updatedApplications",updatedApplications[0].attributes.evaluations?.data?.[0]?.sum)
            setRealApplications(updatedApplications);
        } catch (error) {
            console.error('Error fetching applications and evaluations:', error);
        }
    };

    if (api) {
        fetchApplicationsWithEvaluations();
    }
}, [api, token]);

    // // Fetch Applications information and download as Excel
    // const fetchDataAndDownload = async () => {
    //     try {
    //         const response = await axios.get(
    //             `${process.env.REACT_APP_BASE_URL}/applicant/get-application-list`,
    //             {
    //                 headers: {
    //                     accept: "*/*",
    //                     Event: event,
    //                     SessionKey: "123",
    //                 },
    //                 responseType: 'blob', // Tell axios to get the response as a Blob
    //             }
    //         );
    //         const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    //         FileSaver.saveAs(blob, 'data.xlsx');
    //         setLoading(false);

    //     } catch (error) {
    //         console.error("Error fetching personal information data:", error);
    //         setLoading(false);
    //     }
    // };

    // Fetch Applications information and download as CSV


    // Function to convert JSON data to CSV
    const jsonToCsv = (data) => {
      if (!data || data.length === 0) return '';
      console.log("data test", data);
    
      // Extract main-level headers (excluding 'id')
      const mainHeaders = Object.keys(data[0].attributes).filter(
        (key) => key !== 'id' && key !== 'evaluations' && key !== 'personal_statement'
      );
    
      // Headers for the three fixed evaluations (admin1, admin2, admin3)
      const evaluationHeaders = ['admin1', 'admin2', 'admin3'];
    
      // Headers for personal statement data
      const personalStatementHeaders = ['personal_statement', 'skills', 'social_media','project_detail'];
    
      // Combine all headers
      const headers = [...mainHeaders, ...evaluationHeaders, ...personalStatementHeaders];
      const csvRows = [headers.join(',')]; // Add headers row
    
      // Process each application entry
      data.forEach((item) => {
        const attributes = item.attributes;
    
        // Extract main-level values
        const mainValues = mainHeaders.map((header) =>
          attributes[header] !== null && attributes[header] !== undefined
            ? attributes[header]
            : ''
        );
    
        // Extract data for the three evaluations (or fill with empty if missing)
        const evaluationValues = [0, 1, 2].map((index) => {
          const evalData = attributes.evaluations?.data[index]?.attributes;
          const Data = attributes.evaluations?.data[index];

          const evaluatorName = evalData?.users_permissions_user?.data?.attributes?.username || '';
          const sum = Data?.sum || '';
    
          return evaluatorName ? `${evaluatorName}: ${sum}` : ''; // Format as "Name: Sum"
        });
    
        // Extract personal statement data
        const personalStatement = attributes.personal_statement?.data?.attributes || {};
        const personalValues = personalStatementHeaders.map((header) =>
          personalStatement[header] !== null && personalStatement[header] !== undefined
            ? personalStatement[header]
            : ''
        );
    
        // Combine all values into a single row
        const row = [...mainValues, ...evaluationValues, ...personalValues];
        csvRows.push(row.join(',')); // Join the values with commas
      });
    
      return csvRows.join('\n'); // Join all rows with newlines
    };
    

    // Fetch Applications information and download as CSV
    const fetchDataAndDownload = async () => {
      setLoading(true);
      
      try {
        // Fetch your real applications data from the server (example)

        console.log("realapplications ====>", realapplications);
    
        // Convert JSON data to CSV
        const csvData = jsonToCsv(realapplications);
    
        // Create Blob from CSV data
        const blob = new Blob([csvData], { type: 'text/csv;charset=UTF-8' });
    
        // Use FileSaver to save the Blob as a CSV file
        FileSaver.saveAs(blob, `applicant_data.csv`);
    
        setLoading(false);
      } catch (error) {
        console.error('Error fetching and downloading data:', error);
        setLoading(false);
      }
    };
        // if (type === "report") {
        //   console.log("1111")
        //     // Convert JSON data to CSV
        //     console.log("realapplications ====>",realapplications)
        //     const csvData = jsonToCsv(realapplications);
        //     // console.log(csvData);

        //     const blob = new Blob([csvData], { type: 'text/csv;charset=UTF-8' });

        //     // Use FileSaver to save the Blob as a CSV file
        //     FileSaver.saveAs(blob, `${title}.csv`);

        //     setLoading(false);
        // } else {
        //   console.log("2222")

        //     try {
        //         const response = await axios.get(
        //             `${process.env.REACT_APP_BASE_URL}/applicant/get-application-export-list`,
        //             {
        //                 headers: {
        //                     accept: "*/*",
        //                     Event: event,
        //                     SessionKey: "123",
        //                 }
        //             }
        //         );
        //         const data = response.data.data; // Extract the data array from the response

        //         // Check if data is null or undefined
        //         if (!data) {
        //             console.log('No data received');
        //             return;
        //         }


        //         // Convert JSON data to CSV
        //         const csvData = jsonToCsv(data);
        //         // console.log(csvData);

        //         // Create a Blob from the CSV data
        //         const blob = new Blob([csvData], { type: 'text/csv;charset=UTF-8' });

        //         // Use FileSaver to save the Blob as a CSV file
        //         FileSaver.saveAs(blob, 'data.csv');

        //         setLoading(false);

        //     } catch (error) {
        //         console.error("Error fetching personal information data:", error);
        //         setLoading(false);
        //     }
        // }


  
    const yes = async (id) => {
    
      if (!id || !token) {
        alert("Application ID or Token missing!");
        return;
      }
      let  outgoingData = {
        status:'final_stage'
      }           
     try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
          method: 'PUT', 
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Ensure content-type is set to JSON
          },
          body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
        });
    
        if (!response.ok) {
          throw new Error('Failed to submit form');
        }
        alert("Application moved to the final stage successfully.");

        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error('Error:', error);
      }

    




          
     
    };
    
  const no = async (id) => {
    
    if (!id || !token) {
      alert("Application ID or Token missing!");
      return;
    }
    let  outgoingData = {
      status:'rejected'
    }
      try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
            method: 'PUT', 
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json', // Ensure content-type is set to JSON
            },
            body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
          });
      
          if (!response.ok) {
            throw new Error('Failed to submit form');
          }
          alert("Application moved to the Rejected stage successfully.");

          const data = await response.json();
          console.log(data);
        } catch (error) {
          console.error('Error:', error);
        }



};
    
const Reopen = async (id) => {
  if (!id || !token) {
    alert("Application ID or Token missing!");
    return;
  }

  let outgoingData = {
    status: 'approved'
  };

  try {
    // First API call: Update the application status
    const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: outgoingData })
    });

    if (!response.ok) {
      throw new Error('Failed to submit form');
    }

    alert("Application moved to the shortlist successfully.");

    const data = await response.json();
    console.log("Application update response:", data);

    // Second API call: Delete all evaluations related to this application
   

  } catch (error) {
    console.error('Error:', error);
  }
};


    // const allKeys = ['id', 'name', 'address', 'education', 'experience', 'nationlity', 'gender', 'email', 'contact', 'status'];
    const allKeys = ['id', 'name', 'email'];


    return (
        <div>
            {loading ? (
                <div className="d-flex justify-content-center">
                    <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>
            ) : (<div className="card">
                <div className="card-body">
                    <div className="simple-card-title d-flex justify-content-between align-items-center">
                        <h3 className="text-capitalize mb-1">{title}</h3>
                        <button className="btn-icon m-1 text-capitalize btn btn-primary text-white" onClick={fetchDataAndDownload}>
                            <span className="ul-btn__icon">
                                <i className="i-File-Excel"></i>
                            </span>
                            <span className="ul-btn__text">Export Data</span>
                        </button>
                    </div>
                    {realapplications && realapplications.length > 0 && (
  <table className="table table-bordered">
    <thead>
      <tr>
        {['id', 'First Name', 'Primary Email', 'address', 'primary phone number','admin 1','admin 2','admin 3'].map((key) => (
          <th key={key}>{key.charAt(0).toUpperCase().replace('_', ' ') + key.slice(1)}</th>
        ))}
        <th>Select</th>
      </tr>
    </thead>
    <tbody>
    {realapplications.map((application) => (
  <tr key={application.id}>
    {/* Mapping regular fields like id, first_name, primary_email, etc. */}
    {['id', 'first_name', 'primary_email', 'address', 'primary_phone_number'].map((key) => (
      <td key={key}>
        {key === 'id' ? (
          application.id
        ) : (
          application.attributes[key] || 'N/A'
        )}
      </td>
    ))}

    {/* Admin Columns (evaluation users and their sums) */}
    {/* First dynamically map over evaluations to get the user and their sum */}
    {[0, 1, 2].map((index) => {
      const evaluation = application.attributes.evaluations?.data?.[index]; // Get evaluation at this index
      const username = evaluation?.attributes?.users_permissions_user?.data?.attributes?.username || 'N/A'; // Get username
      const sum = evaluation?.sum || 'N/A'; // Get sum
      const final = 
      evaluation?.attributes?.final_stage === true ? 'Yes' :
      evaluation?.attributes?.final_stage === false ? 'No' : 
      'N/A';

      return (
        <td key={index}>
          {/* Show the username (heading) */}
          <strong>{username}: {sum} </strong>
          <br />
          {/* {final} */}
          {/* Show the sum under the username */}
         
        </td>
      );
    })}

    {/* Select Button */}
    <td>
    {status === 'approved' ? (
  <div>
    <button
      type="button"
      className="btn-icon m-1 text-capitalize btn btn-outline-primary"
      onClick={() => yes(application.id)}
    >
      Yes
    </button>
    <button
      type="button"
      className="btn-icon m-1 text-capitalize btn btn-outline-primary"
      onClick={() => no(application.id)}
    >
      No
    </button>
    <button
    type="button"
    className="btn-icon m-1 text-capitalize btn btn-outline-primary"
    onClick={() => navigate(`/applications/view-application/${application.id}`)}
  >
    <span className="ul-btn__icon">
      <i className="i-Information"></i>
    </span>
    View
  </button>
  </div>
) : status === 'final_stage' ? (
<div>
<button
    type="button"
    className="btn-icon m-1 text-capitalize btn btn-outline-primary"
    onClick={() => navigate(`/applications/view-application/${application.id}`)}
  >
    <span className="ul-btn__icon">
      <i className="i-Information"></i>
    </span>
    View
  </button>
    <button
      type="button"
      className="btn-icon m-1 text-capitalize btn btn-outline-primary"
      onClick={() => no(application.id)}
    >
      Reject
    </button>
  </div>
) : status === 'rejected' ? (
  <div>
 
      <button
        type="button"
        className="btn-icon m-1 text-capitalize btn btn-outline-primary"
        onClick={() => Reopen(application.id)}
      >
        Recover
      </button>
    </div>
  ) : (
  <button
    type="button"
    className="btn-icon m-1 text-capitalize btn btn-outline-primary"
    onClick={() => navigate(`/applications/view-application/${application.id}`)}
  >
    <span className="ul-btn__icon">
      <i className="i-Information"></i>
    </span>
    View
  </button>
)}
    </td>
  </tr>
))}

    </tbody>
  </table>
  )}




                </div>
            </div>
            )}
        </div>
    );
};

export default DashboardTable;


// {
//     "data": [
//         {
//             "reference": "DeveDeem IS Demo Direct AT Demo Organization",
//             "address": "Living in Lahore, Faisalabad, Punjab",
//             "name": "Nadeem R Archer",
//             "id": 9,
//             "file": "http://techmac.ovh/rfmse/ReferenceFileUpload/Reference9",
//             "gender": "Male",
//             "disability": "No ",
//             "nationality": "Foreign National",
//             "contact": "+923012345678, +92515347372",
//             "email": "nademo@gmail.com, testnadeem@g.com",
//             "score": 0,
//             "graceScore": 0,
//             "resume": "http://techmac.ovh/rfmse/ReferenceFileUpload/Resume9",
//             "experience": "4",
//             "coverLetter": "Nadeem Statement",
//             "skills": "Nadeem skill",
//             "dob": "2023-12-21",
//             "network": "Linked In",
//             "totalScore": 0,
//             "fundingRequest": "1",
//             "education": "16"
//         },
//         {
//             "reference": "jjh IS yuu AT ytyt",
//             "address": "3Aj905hvflknh klre[oktregpijnm 12245----, Gakuch, Gilgit-Baltistan",
//             "name": "Muhammad  Mustafa",
//             "id": 23,
//             "file": "http://techmac.ovh/rfmse/ReferenceFileUpload/Reference23",
//             "gender": "Male",
//             "disability": "No ",
//             "nationality": "Foreign National",
//             "contact": "+923334383455",
//             "email": "mustafa-amjad@hotmail.com",
//             "score": 0,
//             "graceScore": 0,
//             "resume": "http://techmac.ovh/rfmse/ReferenceFileUpload/Resume23",
//             "experience": "4",
//             "coverLetter": "rtsytjhg hgjhjh hghgj ghh 4565  ytu 455666 5666 -- 00n hfgjh ",
//             "skills": "hjjytuyt ygyut n tyuyui ry t ",
//             "dob": "2222-02-22",
//             "network": "Website",
//             "totalScore": 0,
//             "fundingRequest": null,
//             "education": "16"
//         }
//     ],
//     "status": 200,
//     "message": "New Applications"
// }