import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationContainer, NotificationManager, } from "react-notifications";

import axios from 'axios';

import Navbar from "./Navbar";
import DashboardHeader from "./DashboardHeader";
const PersonalInformation = () => {


  // Province API Service
  // State variables
  const [provinceData, setProvinceData] = useState();
  // const [provinceId, setProvinceId] = useState();
  // const [provinceCityData, setProvinceCityData] = useState(null);
  // const [cityData, setCityData] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState(false);
  const [applicationid, setApplicationId] = useState();
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const auth = JSON.parse(localStorage.getItem("auth_user_info"));
  const applicantID = auth ? auth.id : null;
  const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
  const status = localStorage.getItem('application_status'); // Assuming the token is stored in localStorage

 console.log("token",token)
  const navigate = useNavigate();
  // Fetch personal information data and set it to formData state
  useEffect(() => {
    const disabledStatuses = ['approved', 'new', 'final_stage', 'rejected'];
    if (disabledStatuses.includes(status)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [status]); // Re-evaluate when the status changes
  useEffect(() => {
    const fetchData = async () => {

     // Fetch the latest campaign using proper pagination syntax
fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`, {
  method: 'GET',
  headers: {
    'Authorization': `Bearer ${token}` // JWT token of the logged-in user
  }
})
  .then(response => response.json())
  .then(campaignResponse => {
    setLoading(false)
    const latestCampaign = campaignResponse.data[0]; // Get the latest campaign

    if (!latestCampaign) {
      console.error('No campaign found');
      return; // If no campaign exists, handle appropriately
    }

    console.log('Latest campaign:', latestCampaign);

    // Fetch applications related to both the user and the latest campaign
    fetch(`${process.env.REACT_APP_BASE_URL_strapi}/users/me?populate[applications][filters][campaign][id][$eq]=${latestCampaign.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}` // JWT token of the logged-in user
      }
    })
      .then(response => response.json())
      .then(user => {
        console.log('User with filtered applications:', user);

        const applications = user.applications; // Extract the filtered applications
        if (applications.length > 0) {
          setApplication(true);
          setFormData(applications[0]); // Set the form data with the application
          setApplicationId(applications[0].id); // Set the application ID
          localStorage.setItem("id", applications[0].id);

        } else {
          
          const auth_user_info = JSON.parse(localStorage.getItem('auth_user_info')); // Assuming the token is stored in localStorage
          console.log("auth_user_info",auth_user_info)
          setFormData({
            first_name: auth_user_info.fname,
            last_name: auth_user_info.lname,
            primary_email: auth_user_info.email1,
            primary_phone_number:auth_user_info.phone
            // Add other fields as necessary
          });
          console.log('No applications related to the latest campaign found for the user');
        }
      })
      .catch(error => {
        console.error('Error fetching user and filtered applications:', error);
      });
  })
  .catch(error => {
    console.error('Error fetching the latest campaign:', error);
  });
    };

    fetchData();

  }, []);

  // Fetch province data when provinceId changes








  // Handle form field changes and update formData state accordingly
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Update form data state
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  
    // Validate the field and dynamically clear errors if the field is valid
    const errorMessage = validateField(name, value);
  
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage || "", // Clear the error if valid
    }));
  };
  
  
  const validateField = (name, value) => {
    switch (name) {
      case "primary_phone_number":
      case "secondary_phone_number":
        if (value && !/^(\+923|03)\d{9}$/.test(value)) 
          return "Phone number is invalid.";
        break;
  
      case "landline":
        if (value && !/^\+?\d{10,15}$/.test(value)) 
          return "Landline number is invalid.";
        break;
  
      case "primary_email":
      case "secondary_email":
        if (value && !/\S+@\S+\.\S+/.test(value)) 
          return "Email is invalid.";
        break;
        
        case "disability":
      if (value === "yes" && !formData.reason) {
        return "Reason is required when disability is 'Yes'.";
      }
      break;
    case "reason":
      if (formData.disability === "yes" && !value.trim()) {
        return "Reason is required.";
      }
      break;
  
      default:
        return null;
    }
  };
  
  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
  
    if (application) {
      // If data is true (or exists), call updateSubmit
      UpdateSubmit(applicationid);
    } else {
      // If data is false (or does not exist), call handleSubmit
      handleSubmit();
    }
  };
  // Handle form submission
  const validateForm = () => {
    const newErrors = {}; // Start with a fresh error object
  
    // Utility function to calculate age
    const calculateAge = (dateString) => {
      const birthDate = new Date(dateString);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
  
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };
  
    // Validate each field and store errors
    if (!formData.first_name) newErrors.first_name = "First name is required";
    if (formData.disability === "yes" && !formData.reason) {
      newErrors.reason = "Reason is required when disability is 'Yes'.";
    }
    if (!formData.last_name) newErrors.last_name = "Last name is required";
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.date_of_birth) {
      newErrors.date_of_birth = "Date of Birth is required";
    } else if (calculateAge(formData.date_of_birth) < 15) {
      newErrors.date_of_birth = "You must be at least 15 years old.";
    }
    if (!formData.disability) newErrors.disability = "Disability status is required";
    if (!formData.nationality) newErrors.nationality = "Nationality is required";
    if (!formData.province) newErrors.province = "Province is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.address) newErrors.address = "Address is required";
    if (!formData.primary_phone_number) {
      newErrors.primary_phone_number = "Primary phone number is required";
    } else if (!/^(\+923|03)\d{9}$/.test(formData.primary_phone_number)) {
      newErrors.primary_phone_number = "Phone number is invalid.";
    }
    if (!formData.primary_email) {
      newErrors.primary_email = "Primary email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.primary_email)) {
      newErrors.primary_email = "Email is invalid.";
    }
  
    // Update the errors state
    setErrors(newErrors);
  
    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };
  
  
  
  const handleSubmit = async (e) => {
  
    // Validate the form before submission
    if (!validateForm()) {
      return; // If validation fails, stop here
    }
  
    const outgoingData = {
      city: formData.city,
      address: formData.address,
      province: formData.province,
      secondary_phone_number: formData.secondary_phone_number,
      date_of_birth: formData.date_of_birth,
      disability: formData.disability,
      reason:formData.reason,
      primary_email: formData.primary_email,
      first_name: formData.first_name,
      gender: formData.gender,
      landline: formData.landline,
      last_name: formData.last_name,
      middle_name: formData.middle_name,
      nationality: formData.nationality,
      primary_phone_number: formData.primary_phone_number,
      secondary_email: formData.secondary_email,
      status:'incomplete'

    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: outgoingData })
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit form');
      }
  
      const data = await response.json();
      console.log("application data",data);
      localStorage.setItem("id",data.id);

      navigate("/application/academic-information");
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const   UpdateSubmit = async(applicationid) => {
  console.log("formData",formData)
  console.log("applicationid",applicationid)
  if (!validateForm()) {
    return; // If validation fails, stop here
  }
    const outgoingData = {
      
        // cityId:formData.cityName,
        city: formData.city,
        address: formData.address,
        province: formData.province,
            // birthCity:formData.birthCity,
      secondary_phone_number: formData.secondary_phone_number,
      date_of_birth: formData.date_of_birth,
      disability: formData.disability,
      reason:formData.reason,
      primary_email: formData.primary_email,
      first_name: formData.first_name,
      gender: formData.gender,
      landline: formData.landline,
      last_name: formData.last_name,
      middle_name: formData.middle_name,
      nationality: formData.nationality ,
      primary_phone_number:formData.primary_phone_number,
      secondary_email:formData.secondary_email,
    
    };

    console.log(outgoingData);

   
    console.log("test",JSON.stringify({ data: outgoingData }));
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${applicationid}`, {
        method: 'PUT', 
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json', // Ensure content-type is set to JSON
        },
        body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit form');
      }
  
      const data = await response.json();
      console.log(data);
      navigate("/application/academic-information");
    } catch (error) {
      console.error('Error:', error);
    }
  };
  //console.log(provinceData)

  // Disablities
  // const disabilities = [
  //   { id: 1, name: 'Visual Impairment' },
  //   { id: 2, name: 'Hearing Impairment' },
  //   { id: 3, name: 'Mobility Impairment' },
  //   { id: 4, name: 'Cognitive Impairment' },
  //   { id: 5, name: 'Psychiatric Impairment' },
  //   { id: 6, name: 'No Disablility' },
  // ];

  // Genders
  const genders = [
    { id: 1, name: 'Male' },
    { id: 2, name: 'Female' },
    { id: 3, name: 'Other' },
    { id: 4, name: 'Prefer not to say' }
  ];

  return (

    <div>
      {/* Header Start */}
      <div className="mb-5">
        <DashboardHeader />
      </div>


      <div className="container row justify-content-center m-auto mb-5">
        <div className="col-md-10">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Navbar />
            </div>
            <div className="col-sm-12 col-md-8 mb-4">
              {/* <MyForm /> */}
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>

              ) : (
                <form onSubmit={handleFormSubmit }>
                  <div className="card">
                    <div className="card-body">
                      <h4>Personal Information</h4>
                      <h5>Basic Information</h5>
                      <div className="form-group mb-3">
                        <label htmlFor="firstName" className="">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          placeholder="Enter First Name"
                          name="first_name"
                          value={formData?.first_name || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                          
                        />
                         {errors.first_name && <p className="text-danger">{errors.first_name}</p>}
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="middleName" className="">
                          Middle Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="middleName"
                          name="middle_name"
                          placeholder="Enter Middle Name"
                          value={formData?.middle_name || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                         {errors.middle_name && <p className="text-danger">{errors.middle_name}</p>}
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="lastName" className="">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          name="last_name"
                          placeholder="Enter Last Name"
                          value={formData?.last_name || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                       {errors.last_name && <p className="text-danger">{errors.last_name}</p>}

                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="gender" className="">
                          Gender <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="gender"
                          name="gender"
                          placeholder="Select Gender"
                          value={formData?.gender || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        >

                          {(!formData?.gender || formData?.gender === null) && (
                            <option key={0} value={''}>
                              Select Gender
                            </option>
                          )}
                             <option value= "Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                          <option value="PNTS">Prefer Not To Say</option>


                          
                        </select>
                        {errors.gender && <p className="text-danger">{errors.gender}</p>}

                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="dobirth" className="">
                          Date of Birth <span className="text-danger">*</span>
                        </label>
                        <input
                          type="Date"
                          className="form-control"
                          id="dobirth"
                          name="date_of_birth"
                          placeholder="Select Date of Birth"
                          value={formData?.date_of_birth || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                        {errors.date_of_birth && <p className="text-danger">{errors.date_of_birth}</p>}

                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="disabilities" className="">
                          Disability <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="disabilities"
                          name="disability"
                          placeholder="Select Disability"
                          value={formData?.disability || 0}
                          onChange={handleChange}
                          disabled={isDisabled}
                        >
                     

                          {(!formData?.disablity || formData?.disablity === 0) && (
                            <option key={0} value={0}>
                              Select Disability
                            </option>
                          )}
                          <option value= "no">No</option>
                          <option value="yes">Yes</option>
                         
                        </select>
                        {errors.disability && <p className="text-danger">{errors.disability}</p>}
                      </div>
                      {formData?.disability === "yes" && (
                          <div className="form-group mb-3">
                            <label htmlFor="reason" className="">
                            Please Specify <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="reason"
                              name="reason"
                              placeholder="Enter reason"
                              value={formData?.reason || ''}
                              onChange={handleChange}
                              disabled={isDisabled}
                            />
                            {errors.reason && <p className="text-danger">{errors.reason}</p>}
                          </div>
                        )}
                      <div className="form-group mb-3">
                        <label htmlFor="nationality" className="">
                          Nationality <span className="text-danger">*</span>
                        </label>
                        <select
                          id="nationality"
                          className="form-control"
                          name="nationality"
                          placeholder=" Select Nationality"
                          value={formData?.nationality || 0}
                          onChange={handleChange}
                          disabled={isDisabled}
                        >
                                              

                          <option key={0} value={0}>
                            Select Nationality
                          </option>

                          <option key={1} value={true}>
                            Pakistan
                          </option>
                          <option key={2} value={false}>
                            Other
                          </option>
                        </select>
                        {errors.nationality && <p className="text-danger">{errors.nationality}</p>}
                      </div>

                      <h5>Address</h5>
                      {/* <div className="form-group mb-3">
                        <label htmlFor="birthCityId" className="">
                          Place of Birth <span className="text-danger">*</span>
                        </label>
                        <select
                          id="birthCityId"
                          className="form-control"
                          name="birthCity"
                          placeholder="Select Place of Birth"
                          value={formData?.birthCity || 0}
                          onChange={handleChange}

                        >
                          <option key={0} value={0}>
                            Select Place of Birth
                          </option>
                          {cityData.data &&
                            cityData.data.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div> */}

                      <div className="form-group mb-3">
                        <label htmlFor="province">Province <span className="text-danger">*</span></label>
                        <select
                          id="province"
                          className="form-control"
                          name="province"
                          placeholder=" Select Province"
                          onChange={handleChange}
                          value={formData?.province || 0}
                          disabled={isDisabled}
                        >
                          {(!formData?.provinceName || formData?.provinceName === 0) && (
                            <option key={0} value={0}>
                              Select Province
                            </option>
                          )}
                         <option value="Punjab">Punjab</option>
                        <option value="Sindh">Sindh</option>
                        <option value="Kpk">Khyber Pakhtunkhwa</option>
                        <option value="Balochistan">Balochistan</option>
                        <option value="Islamabad">Islamabad Capital Territory</option>
                        <option value="Gilgit-Baltistan">Gilgit-Baltistan</option>
                        <option value="Ajk">Azad Jammu and Kashmir</option>

                         
                        </select>
                        {errors.province && <p className="text-danger">{errors.province}</p>}

                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="pCity">City <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="pCity"
                          name="city"
                          placeholder="Enter your city name"
                          value={formData?.city || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                          {errors.city && <p className="text-danger">{errors.city}</p>}

                        {/* <select
                          id="pCity"
                          className="form-control"
                          name="cityName"
                          placeholder=" Select City"
                          value={formData?.cityName || 0}
                          onChange={handleChange}
                        >



                          {!provinceId ? (
                            <option key={0} value={0}>
                              Select Province First
                            </option>
                          ) : (
                            <>
                              <option key={0} value={0}>
                                Select your city
                              </option>
                              {provinceCityData &&
                                provinceCityData.data.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))
                              }
                            </>
                          )}

                        </select> */}
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="streetAddress" className="">
                          Address <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="streetAddress"
                          name="address"
                          placeholder="Enter Address"
                          value={formData?.address || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                         {errors.address && <p className="text-danger">{errors.address}</p>}

                      </div>

                      <h5>Contact Information</h5>
                      <div className="form-group mb-3">
                        <label htmlFor="mobile1" className="">
                          Primary Phone Number <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="mobile1"
                          name="primary_phone_number"
                          placeholder="0300000000000"
                          value={formData?.primary_phone_number || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                        {errors.primary_phone_number && <p className="text-danger">{errors.primary_phone_number}</p>}

                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="mobile2" className="">
                          Secondary Phone Number
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="mobile2"
                          name="secondary_phone_number"
                          placeholder="+0300000000000"
                          value={formData?.secondary_phone_number || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                          {errors.secondary_phone_number && <p className="text-danger">{errors.secondary_phone_number}</p>}

                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="landline" className="">
                          Landline
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="landline"
                          name="landline"
                          placeholder="+0300000000000"
                          value={formData?.landline || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                        {errors.landline && <p className="text-danger">{errors.landline}</p>}

                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="email1" className="">
                          Primary Email <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="email1"
                          name="primary_email"
                          placeholder="Student@gmail.com"
                          value={formData?.primary_email || ''}
                          onChange={handleChange}
                          disabled={isDisabled}

                        />
                         {errors.primary_email && <p className="text-danger">{errors.primary_email}</p>}

                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="email2" className="">
                          Secondary Email
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="email2"
                          name="secondary_email"
                          placeholder="Student@gmail.com"
                          value={formData?.secondary_email || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                           {errors.secondary_email && <p className="text-danger">{errors.secondary_email}</p>}

                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="mc-footer">
                        <div className="row text-center">
                          <div className="col-lg-12 ">

                            <button type="submit" className="btn btn-primary text-white m-1"  disabled={isDisabled}>
                              Save & Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>

      </div>

      {/* Main End */}
      <NotificationContainer />
    </div>
  );
};

export default PersonalInformation;