import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardHeader from "./DashboardHeader";
import Navbar from "./Navbar";


const ApplicationInstructions = () => {


    const navigate = useNavigate();
    return (
        <div>
            {/* Header Start */}
            <div className="mb-5">

                <DashboardHeader />
            </div>
            <div className="container row justify-content-center m-auto mb-5">
                <div className="col-md-10">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-4">
                            <Navbar />
                        </div>
                        <div className="col-sm-12 col-md-8 mb-4">
                            <div className="card">
                                <div className="card-body">
                                <div className="card-body">
                                    <h1>Instructions</h1>
                                    <p>Welcome! You have successfully started an application for Renewables First's <strong>Margalla School on Energy & Climate (MSEC).</strong></p>
                                    <p>Thank you for your interest in the program. We look forward to receiving your completed application.</p>

                                    <h5><strong>Key Dates:</strong></h5>
                                    <ul>
                                        <li><strong>Application deadline:</strong> Tuesday, December 10th, 2024, at 11:59 PM (PKT)</li>
                                        <li><strong>Final Decisions:</strong> Mid-January 2025</li>
                                        <li><strong>Two-week Fellowship starts:</strong> February 2025</li>
                                    </ul>

                                    <h5><strong>Important Notes:</strong></h5>
                                    <ul>
                                        <li>Complete all sections of the application before the deadline</li>
                                        <li>No extensions or late applications will be accepted</li>
                                        <li>You can save your progress and return to complete sections later.</li>
                                        <li>Review all information carefully before final submission</li>
                                        <li>Use the "Continue" button at the bottom of each page to save and advance</li>
                                        <li>Before submitting, ensure all sections are complete and accurate</li>
                                    </ul>

                                    <p>For any questions, contact us at <a href="mailto:msec@renewablesfirst.org">msec@renewablesfirst.org</a>.</p>
                                    <p>Click the "Continue" button to begin your application.</p>
                                    </div>


                                </div>
                                <div className="card-footer">
                                    <div className="mc-footer">
                                        <div className="row text-center">
                                            <div className="col-lg-12 ">
                                                <button type="button" className="btn btn-primary m-1 text-white" onClick={() => navigate('/application/personal-information')}>
                                                    Continue
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ApplicationInstructions;

