import React from 'react'

import AdminNav from "./AdminNav";
import DashboardHeader from "./DashboardHeader";
import DashboardSearch from './DashboardSearch';
import DashboardTable from './DashboardTable';


const NewApplications = () => {

  const handleSearch = (searchFields) => {
    // Call your search API here with the searchFields
    console.log(searchFields);
  }

  return (

    <div className="app-admin-wrap layout-sidebar-large">
      <DashboardHeader />
      <AdminNav />
      <div className="main-content-wrap d-flex flex-column sidenav-open">
        <h1>New Applications</h1>

        <DashboardSearch onSearch={handleSearch} />


        <DashboardTable title={'New Applications'} event={'new'} type={"applications"} status={'new'} />

      </div>
    </div>
  )
}

export default NewApplications


