import { merge } from "lodash";
import { DropDownMenu } from "@gull";
import { classList, isMobile } from "@utils";
import Srcollbar from "react-perfect-scrollbar";
import { dashboardNavigations } from "./dashboardNavigations";
import ScrollBar from "react-perfect-scrollbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setLayoutSettings } from "app/redux/layout/layoutSlice";

const AdminNav = () => {
  let windowListener = null;
  const location = useLocation();
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.layout);

  const [state, setState] = useState({
    selectedItem: null,
    navOpen: true,
    secondaryNavOpen: false,
  });

  const onMainItemMouseLeave = () => {
    // closeSecSidenav();
  };

  const setSelected = (selectedItem) => {
    setState({ selectedItem });
  };

  const removeSelected = () => {
    setState({ selectedItem: null });
  };

  const findSelectedItem = () => {
    dashboardNavigations.forEach((item) => {
      if (item.sub) {
        item.sub.forEach((child) => {
          if (child.sub) {
            child.sub.forEach((grandChild) => {
              if (grandChild.path === location.pathname) {
                setSelected(item);
              }
            });
          } else {
            if (child.path === location.pathname) {
              setSelected(item);
            }
          }
        });
      } else {
        if (item.path === location.pathname) {
          setSelected(item);
        }
      }
    });
  };

  const openSecSidenav = () => {
    dispatch(
      setLayoutSettings(
        merge({}, settings, {
          layout1Settings: {
            leftSidebar: {
              secondaryNavOpen: true,
            },
          },
        })
      )
    );
  };

  const closeSecSidenav = () => {
    let other = {};

    if (isMobile()) {
      other.open = false;
    }

    dispatch(
      setLayoutSettings(
        merge({}, settings, {
          layout1Settings: {
            leftSidebar: {
              ...other,
              secondaryNavOpen: false,
            },
          },
        })
      )
    );
  };

  const closeSidenav = () => {
    dispatch(
      setLayoutSettings(
        merge({}, settings, {
          layout1Settings: {
            leftSidebar: {
              open: false,
              secondaryNavOpen: false,
            },
          },
        })
      )
    );
  };

  const openSidenav = () => {
    dispatch(
      setLayoutSettings(
        merge({}, settings, {
          layout1Settings: {
            leftSidebar: {
              open: true,
            },
          },
        })
      )
    );
  };

  const onMainItemMouseEnter = (item) => {
    if (item.type === "dropDown") {
      setSelected(item);
      openSecSidenav();
    } else {
      setSelected(item);
      closeSecSidenav();
    }
  };

  useEffect(() => {
    // if (state.selectedItem === null) closeSecSidenav();
    setTimeout(() => {
      findSelectedItem();
    });

    if (window) {
      if (window.innerWidth < 1200) {
        closeSidenav();
      } else {
        openSidenav();
      }
    }

    windowListener = window.addEventListener("resize", ({ target }) => {
      if (window.innerWidth < 1200) {
        closeSidenav();
      } else {
        openSidenav();
      }
    });
  }, []);

  useEffect(() => {
    if (windowListener) {
      window.removeEventListener("resize", windowListener);
    }
  }, [windowListener]);

  return (
    <div className="side-content-wrap">
      <Srcollbar
        className={classList({
          "sidebar-left o-hidden rtl-ps-none": true,
          open: settings.layout1Settings.leftSidebar.open,
        })}
      // id="mainsidenav"
      >
        <ul className="navigation-left">
          {dashboardNavigations.map((item, i) => (
            <li
              className={classList({
                "nav-item": true,
                active: state.selectedItem === item,
              })}
              onMouseEnter={() => {
                onMainItemMouseEnter(item);
              }}
              onMouseLeave={onMainItemMouseLeave}
              key={i}
            >
              {item.path && item.type !== "extLink" && (
                <Link className="nav-item-hold" to={item.path}>
                  <i className={`nav-icon ${item.icon}`}></i>
                  <span className="nav-text">{item.name}</span>
                </Link>
              )}
              {item.path && item.type === "extLink" && (
                <a className="nav-item-hold" href={item.path}>
                  <i className={`nav-icon ${item.icon}`}></i>
                  <span className="nav-text">{item.name}</span>
                </a>
              )}
              {!item.path && (
                <div className="nav-item-hold">
                  <i className={`nav-icon ${item.icon}`}></i>
                  <span className="nav-text">{item.name}</span>
                </div>
              )}
              <div className="triangle"></div>
            </li>
          ))}
        </ul>
      </Srcollbar>

      <ScrollBar
        className={classList({
          "sidebar-left-secondary o-hidden rtl-ps-none": true,
          open: settings.layout1Settings.leftSidebar.secondaryNavOpen,
        })}
      >
        {state.selectedItem && state.selectedItem.sub && (
          <DropDownMenu
            menu={state.selectedItem.sub}
            closeSecSidenav={closeSecSidenav}
          ></DropDownMenu>
        )}
        <span></span>
      </ScrollBar>
      <div
        onMouseEnter={closeSecSidenav}
        className={classList({
          "sidebar-overlay": true,
          open: settings.layout1Settings.leftSidebar.secondaryNavOpen,
        })}
      ></div>
    </div>
  );
};

export default AdminNav;





//
// import React from 'react'
// import { Link } from 'react-router-dom'

// const AdminNav = () => {
//   return (
//     <nav className="main-menu">
//       <div>
//         <a className="logo" href="http://startific.com"></a>
//       </div>
//       <div className="settings"></div>
//       <div className="scrollbar" id="style-1">
//         <ul>
//           <li>
//             <Link to="/Dashboard">
//               <a href="#">
//                 <i className="fa fa-home fa-lg"></i>
//                 <span className="nav-text">Dashboard </span>
//               </a>
//             </Link>
//           </li>
//           <li>
//             <Link to="/Applications">
//               <i className="fa fa-user fa-lg"></i>
//               <span className="nav-text">Applications</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/UnderReviewApplication">
//               <i className="fa fa-envelope-o fa-lg"></i>
//               <span className="nav-text">Under Review Application</span>
//             </Link>
//           </li>
//           {/* ... Other list items */}
//           <li>
//             <Link to="/NewApplication">
//               <i className="fa fa-question-circle fa-lg"></i>
//               <span className="nav-text">New Application</span>
//             </Link>
//           </li>
//         </ul>
//         <ul className="logout">
//           <li>
//             <Link to="/SearchApplication">
//               <i className="fa fa-lightbulb-o fa-lg"></i>
//               <span className="nav-text">Search Application</span>
//             </Link>
//           </li>

//         </ul>
//         <ul className="logout">
//           <li>
//             <Link to="/Application">
//               <i className="fa fa-lightbulb-o fa-lg"></i>
//               <span className="nav-text">Application</span>
//             </Link>
//           </li>

//         </ul>
//         <ul className="logout">
//           <li>
//             <Link to="/LastApplication">
//               <i className="fa fa-lightbulb-o fa-lg"></i>
//               <span className="nav-text">Last Application</span>
//             </Link>
//           </li>

//         </ul>
//         <ul className="logout">
//           <li>
//             <Link to="/FirstApplication">
//               <i className="fa fa-lightbulb-o fa-lg"></i>
//               <span className="nav-text">First Application</span>
//             </Link>
//           </li>

//         </ul>
//         <ul className="logout">
//           <li>
//             <Link to="/PersonalStatement">
//               <i className="fa fa-lightbulb-o fa-lg"></i>
//               <span className="nav-text">Personal Statement</span>
//             </Link>
//           </li>

//         </ul>
//         <ul className="logout">
//           <li>
//             <Link to="/FundingStatement">
//               <i className="fa fa-lightbulb-o fa-lg"></i>
//               <span className="nav-text">Fundding Statement</span>
//             </Link>
//           </li>

//         </ul>
//         <ul className="logout">
//           <li>
//             <Link to="/AcademicApplication">
//               <i className="fa fa-lightbulb-o fa-lg"></i>
//               <span className="nav-text">Academic Application</span>
//             </Link>
//           </li>

//         </ul>

//       </div>
//     </nav>
//   )
// }

// export default AdminNav