// import "../fake-db";
// import "../styles/app/app.scss";
// import Auth from "./auth/Auth";
// import React from "react";
// import routes from "./routes";
// import { RouterProvider } from "react-router-dom";

// function App() {
//   return (
//     <Auth>
//       <RouterProvider router={routes} />
//     </Auth>
//   );
// }

// export default App;

import "../fake-db";
import "../styles/app/app.scss";

import "../styles/css/style.css";
import "../styles/css/responsive.css";
import "../styles/css/slick.css";

import Auth from "./auth/Auth";
import React from "react";
import routes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Auth>
      <Router>
        {routes}
      </Router>
    </Auth>
  );
}

export default App;