import React from "react";
import AdminNav from "./AdminNav";
import DashboardHeader from "./DashboardHeader";
// import DashboardSearch from './DashboardSearch';
import DashboardTable from './DashboardTable';


function GbApplicantReport() {
    return (
        <>
            <div className="app-admin-wrap layout-sidebar-large">
                <DashboardHeader />
                <AdminNav />
                <div className="main-content-wrap d-flex flex-column sidenav-open">
                    <h1>Gilgit Baltistan Applicant Report</h1>

                    {/* <DashboardSearch onSearch={handleSearch} /> */}


                    <DashboardTable title={'Gilgit Baltistan Applicant Report'} event={'gb'} type={"report"} province={'Gilgit-Baltistan'}/>

                </div>
            </div>
        </>
    );
}

export default GbApplicantReport;