import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true); // Set loading to true initially
  const [navTarget, setNavTarget] = useState(null);
  const auth = JSON.parse(localStorage.getItem("auth_user_info"));

  const isPrivacyPolicyDataAvailable = () => {
    const applicationId = localStorage.getItem("id");
    const academic_detail = localStorage.getItem("acedemic_detail");
    const reference_letter = localStorage.getItem("Refrence_letter");
    const personal_statement = localStorage.getItem("personal_statement");
    const professional_detail = localStorage.getItem("professional_detail");
    const resume = localStorage.getItem("resume");

    // Ensure none of the values are undefined, null, or empty
    return [
      applicationId,
      academic_detail,
      reference_letter,
      personal_statement,
      professional_detail,
      resume,
    ].every((item) => item && item !== "undefined" && item !== "null");
  };

  useEffect(() => {
    const ignoredRoutes = ["/signup", "/signin", "/sessions/forgot-password"];

    const adminRoutes = [
      "/dashboard",
      "/applications/new-applications",
      "/applications/approved-applications",
      "/applications/final-applications",
      "/applications/under-review-applications",
      "/applications/rejected-applications",
      "/applications/view-application",
      "/applicant/report/not-submitted",
      "/applicant/report/signup",
      "/applicant/report/male",
      "/applicant/report/female",
      "/applicant/report/punjab",
      "/applicant/report/sindh",
      "/applicant/report/balochistan",
      "/applicant/report/kpk",
      "/applicant/report/isb",
      "/applicant/report/gb",
      "/applicant/report/ak",
    ];

    const userRoutes = [
      "/application/instructions",
      "/application/personal-information",
      "/application/academic-information",
      "/application/professional-information",
      "/application/personal-statement",
      "/application/reference-letter",
      "/application/funding-options",
      "/application/privacy-policy-statement",
      "/application/resume",
    ];

    // If not authenticated and on a protected route, redirect to /signin
    const isProtectedRoute = adminRoutes
      .concat(userRoutes)
      .some((route) => location.pathname.includes(route));

    if (!auth && isProtectedRoute) {
      setNavTarget("/signin");
      setIsLoading(false);
      return;
    }

    if (auth && ignoredRoutes.some((route) => location.pathname.includes(route))) {
      if (auth.type === 2) {
        setNavTarget(auth.status === 1 ? "/signup" : "/application/instructions");
      } else if (auth.type === 1) {
        setNavTarget("/dashboard");
      }
      setIsLoading(false);
      return;
    }

    if (!auth || auth.status === 1) {
      if (location.pathname === "/") {
        setNavTarget("/");
        setIsLoading(false);
        return;
      }
    }
    console.log("idhr aia h2 ")

    // Check for data availability for /application/privacy-policy-statement
    if (location.pathname === "/application/privacy-policy-statement" && !isPrivacyPolicyDataAvailable()) {
      console.log("Data missing, redirecting to /application/instructions");
      window.location.href = "/application/instructions"; // Force full page reload
      return;
    }

    setIsLoading(false);
  }, [location, auth]);

  useEffect(() => {
    if (navTarget) {
      navigate(navTarget);
    }
  }, [navTarget, navigate]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-bubble spinner-bubble-primary m-5"></div>
      </div>
    ); // Replace with your actual loading component
  }

  return children;
};

export default AuthGuard;
