import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import DashboardHeader from "./DashboardHeader";

const FundingOptions = () => {
  const [content, setContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const navigate = useNavigate();
  const token = localStorage.getItem("token"); 
  const reference_letter = localStorage.getItem("Refrence_letter");
  
  const isPrivacyPolicyDataAvailable = () => {
    const requiredItems = [
      localStorage.getItem("id"),
      localStorage.getItem("acedemic_detail"),
      reference_letter,
      localStorage.getItem("personal_statement"),
      localStorage.getItem("professional_detail"),
      localStorage.getItem("resume")
    ];
    return requiredItems.every(item => item && item !== "undefined" && item !== "null");
  };
  
  const [isDataAvailable, setIsDataAvailable] = useState(isPrivacyPolicyDataAvailable());
  const [showError, setShowError] = useState(!isDataAvailable); // Show error if data is missing
  
  useEffect(() => {
    setIsDataAvailable(isPrivacyPolicyDataAvailable());
    setShowError(!isPrivacyPolicyDataAvailable());
  }, []);
  
  useEffect(() => {
    const fetchReferenceLetter = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/reference-letters/${reference_letter}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setLoading(false);
        
        if (data?.data?.attributes?.scholarship) {
          setContent(true);
        }
      } catch (error) {
        console.error("Error fetching reference letter:", error);
        setLoading(false);
      }
    };

    if (reference_letter) fetchReferenceLetter();
  }, [reference_letter, token]);

  const handleContentChange = () => {
    setContent(prevContent => !prevContent);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const data = { scholarship: content };
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/reference-letters/${reference_letter}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data }),
      });
      if (response.ok) {
        navigate("/application/privacy-policy-statement");
      } else {
        console.error("Error updating scholarship information.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <DashboardHeader />
      <div className="container row justify-content-center m-auto mb-5">
        <div className="col-md-10">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Navbar />
            </div>
            <div className="col-sm-12 col-md-8 mb-4">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="card">
                    <div className="card-body">
                      <h4>Funding Options</h4>
                      <p>
                        Please note that this training program covers the costs of accommodation and food during the two-week training in Islamabad. However, participants are responsible for making their own travel arrangements to and from the training location.
                      </p>
                      <p>
                        For those seeking financial aid to cover travel expenses, a limited number of scholarships are available to eligible applicants. Scholarship recipients will be chosen based on a competitive selection process that considers both financial need and merit.
                      </p>
                      <div className="ul-widget-app__task-list my-4">
                        <label className="checkbox checkbox-primary">
                          <input 
                            type="checkbox" 
                            checked={content} 
                            onChange={handleContentChange}  
                            disabled={isDisabled}
                          />
                          <strong>
                            Select the checkbox below if you wish to apply for this scholarship. Applying for the scholarship will not affect your selection process.
                          </strong>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="mc-footer">
                        <div className="row text-center">
                          <div className="col-lg-12 ">
                            <button 
                              type="submit" 
                              className="btn btn-primary text-white m-1"
                              disabled={!isDataAvailable}
                              
                            >
                              Save & Next
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-secondary m-1"
                              onClick={() => navigate('/application/privacy-policy-statement')}
                              disabled={!isDataAvailable}
                            >
                              Skip
                            </button>
                            {showError && (
                              <p className="text-danger mt-2">
                                You can't proceed to the next step until all required forms are filled out.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundingOptions;
