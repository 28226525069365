import React from "react";
import ReactEcharts from "echarts-for-react";

const PieChart = ({ height, color = [], data }) => {

  const option = {
    color: ["#70bf6a", "#79c675", "#83cd80", "#8dd48b", "#97db96", "#a1e2a1", "#68a768"],
    tooltip: {
      show: true,
      backgroundColor: "rgba(0, 0, 0, .8)"
    },

    series: [
      {
        name: "Apllication by Province",
        type: "pie",
        radius: "60%",
        center: ["50%", "50%"],
        data: data,
        // data: [
        //   { value: 535, name: "Punjab" },
        //   { value: 310, name: "Sindh" },
        //   { value: 234, name: "Khyber Khyber Pakhtunkhwa" },
        //   { value: 155, name: "Blochistan" },
        //   { value: 130, name: "Islamabad" },
        //   { value: 348, name: "Gilgit-Baltistan" },
        //   { value: 348, name: "Azad Kashmir" }
        // ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)"
          }
        }
      }
    ]
  };

  return <ReactEcharts style={{ height: height }} option={option} />;
};

export default PieChart;
