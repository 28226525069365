import React from "react";
import AdminNav from "./AdminNav";
import DashboardHeader from "./DashboardHeader";
import DashboardView from "./DashboardView";


const Dashboard = () => {

  return (
    <div className="app-admin-wrap layout-sidebar-large">
      <DashboardHeader />
      <AdminNav />

      <DashboardView />

    </div>
  )
};

export default Dashboard
