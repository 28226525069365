import React from "react";
import AdminNav from "./AdminNav";
import DashboardHeader from "./DashboardHeader";
// import DashboardSearch from './DashboardSearch';
import DashboardTable from './DashboardTable';


function PunjabApplicantReport() {
    return (
        <>
            <div className="app-admin-wrap layout-sidebar-large">
                <DashboardHeader />
                <AdminNav />
                <div className="main-content-wrap d-flex flex-column sidenav-open">
                    <h1>Punjab Applicant Report</h1>

                    {/* <DashboardSearch onSearch={handleSearch} /> */}


                    <DashboardTable title={'Punjab Applicant Report'} event={'punjab'} type={"report"} province={'Punjab'}/>

                </div>
            </div>
        </>
    );
}

export default PunjabApplicantReport;